import styled from 'styled-components'
import themes from './themes'
const { colors, fontFamily } = themes

export const HeroDescription = styled.div`
  max-width: 500px;
  padding: 50px 0;
  p {
    font-size: 20px;
  }
`

export const HiHero = styled.h1`
  color: ${colors.secondary};
  font-family: ${fontFamily};
  font-size: 20px;
  width: 100%;
`

export const NameHero = styled.h1`
  font-family: ${fontFamily};
  width: 100%;
  color: white;
  font-size: 50px;
  font-weight: 500;

  @media (min-width: 1200px) {
    font-size: 70px;
  }
`

export const LavuLink = styled.a`
  color: ${colors.secondary};
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`
