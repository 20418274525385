import styled from 'styled-components'
import { FaBars, FaTimes } from 'react-icons/fa'
import { ActionLink } from '../../styles/shared'
import themes from '../../styles/themes'
const { colors, fontFamily } = themes

export { ActionLink }

export const FaBarsStyled = styled(FaBars)`
  && {
    color: ${colors.secondary};
    cursor: pointer;
  }
`

export const FaTimesStyled = styled(FaTimes)`
  && {
    position: fixed;
    top: 50px;
    right: 50px;
    color: ${colors.secondary};
    cursor: pointer;
  }
`

export const HeaderWrapper = styled.header`
  display: flex;
  width: 100%;
  z-index: 11;
  height: 70px;
  padding: 0px 50px;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.main};
  box-shadow: ${colors.footer} 0px 2px 4px;
`

export const NavWrapper = styled.nav`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: ${fontFamily};
  color: rgb(204, 214, 246);
  counter-reset: item 0;
  position: relative;
  z-index: 12;
`

export const NavLinks = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  @media (min-width: 992px) {
    display: block;
    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`

export const MobileNav = styled.div`
  @media (min-width: 992px) {
    display: none;
  }
`

export const MobileSidenav = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 100%;
  z-index: 10;
  transform: translateX(${({ sidebar }) => (sidebar ? '0vw' : '100vw')});
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  visibility: ${({ sidebar }) => (sidebar ? 'visible' : 'hidden')};

  .inner {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgb(23, 42, 69);
    width: 50vw;
    height: 100%;
    position: relative;
    right: 0px;
    margin-left: auto;
    box-shadow: rgba(10, 25, 47, 0.7) -2px 0px 4px;
    padding: 50px;
  }
`

export const NavLink = styled.li`
  position: relative;
  font-size: ${props => (props.big ? 20 : 14)}px;
  margin: 0px 10px;
  counter-increment: item 1;

  &:before {
    content: '0' counter(item) '.';
    text-align: right;
    color: ${colors.secondary};
    font-size: ${props => (props.big ? 18 : 12)}px;
  }

  a {
    display: inline-block;
    text-decoration-skip-ink: auto;
    color: inherit;
    position: relative;
    cursor: pointer;
    text-decoration: none;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    padding: 12px 10px;

    &:hover {
      color: ${colors.secondary};
      outline: 0px;
    }
  }
`
