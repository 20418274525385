import React from 'react'
import { Link } from 'gatsby'

import {
  HeaderWrapper,
  NavWrapper,
  NavLinks,
  NavLink,
  ActionLink,
  MobileNav,
  MobileSidenav,
  FaBarsStyled,
  FaTimesStyled,
} from './styles'

const navLinks = [
  { to: '/home', name: 'Home' },
  { to: '/', name: 'Blog' },
]

const NavItems = ({ big = false }) =>
  navLinks.map(({ to, name }) => (
    <NavLink big={big} key={name}>
      <Link to={to} state={{ hash: to }}>
        {name}
      </Link>
    </NavLink>
  ))

const Header = () => {
  const [sidebar, setSidebar] = React.useState(false)
  const sideNavRef = React.createRef()

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const handleClickOutside = e => {
    if (sideNavRef.current && sideNavRef.current === e.target) {
      return toggleSidenav()
    }
  }

  const toggleSidenav = () => setSidebar(!sidebar)

  return (
    <HeaderWrapper>
      <NavWrapper>
        <div>
          <ActionLink
            to="/home"
            style={{ fontSize: 24, padding: '5px 10px', fontWeight: 'bold' }}
          >
            H
          </ActionLink>
        </div>
        <div>
          <NavLinks>
            <div>
              <NavItems />
            </div>
          </NavLinks>

          <MobileNav>
            <FaBarsStyled size="2em" onClick={toggleSidenav} />
            <MobileSidenav sidebar={sidebar} ref={sideNavRef}>
              <div className="inner">
                <FaTimesStyled size="2em" onClick={toggleSidenav} />
                <ul>
                  <NavItems big={true} />
                </ul>
              </div>
            </MobileSidenav>
          </MobileNav>
        </div>
      </NavWrapper>
    </HeaderWrapper>
  )
}

export default Header
