export default {
  colors: {
    main: '#000022',
    secondary: '#F4D35E',
    projectItem: '#04052E',
    footer: '#04052E',
  },
  fontFamily: `Quicksand, Roboto, Arial, sans-serif;`,
  fontFamilyRoboto: 'Roboto,Arial,sans-serif',
}
