import styled from 'styled-components'
import themes from './themes'
const { colors, fontFamily, fontFamilyRoboto } = themes

export const PostWrapper = styled.div`
  font-family: ${fontFamily};
  padding-top: 50px;
  @media (min-width: 992px) {
    display: grid;
    grid-template-areas: 'nav article';

    grid-template-columns: 20% 1fr;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    margin: 0;
  }
`

export const LeftSide = styled.div`
  grid-area: nav;
  div {
    justify-content: flex-start;
    .link {
      margin: 0 10px 10px 0;
    }
  }
`

export const RightSide = styled.div`
  grid-area: article;
`

export const BlogPostContainer = styled.div`
  font-family: ${fontFamilyRoboto};
  margin-top: 25px;

  .blog-post-header {
    margin-bottom: 25px;
    .blog-post-title {
      font-size: 36px;
      font-weight: bold;
      margin: 25px 0 20px;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      .blog-post-date {
        margin: 15px 0;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .blog-post-description {
      padding: 0px 0px 45px 0;
      font-size: 18px;
      font-weight: 400;
      text-align: left;
      text-transform: none;
    }
  }

  .blog-post-content {
    font-size: 18px;
    color: white;

    p {
      margin: 0 0 25px;
    }
    h1 {
      font-size: 22px;
    }
    h2 {
      font-size: 20px;
    }
  }
`

export const PostLinkSection = styled.div`
  display: flex;
  padding: 20px 15px;
  border-radius: 2px;
  align-items: flex-start;

  .description {
    flex: 1;
    .title {
      font-size: 24px;
      font-weight: 100;
    }
  }

  .tag {
    margin-top: 12px;
  }

  .dates {
    width: 250px;
    text-align: right;
  }

  box-shadow: white 0px 0px 1px 0px;
  margin-bottom: 5px;
  &:hover {
    cursor: pointer;
    background: #403d58;
    color: ${colors.secondary};
  }
`

export const TagsSection = styled.div`
  font-family: ${fontFamily};
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  > .link {
    margin-right: 10px;
    border: none;
    text-decoration: none;
  }
`

export const Tag = styled.div`
  font-family: ${fontFamily};
  background: #54426b;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding: 8px 15px;
  display: inline-block;
  border-radius: 4px;

  &.golang,
  .go {
    background: #38aecc;
  }

  &.git {
    background: #ff521b;
  }
`
