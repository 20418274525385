import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'
import { Link } from 'gatsby'
import themes from './themes'

const { colors, fontFamily } = themes

export const GlobalStyle = createGlobalStyle`
  ${reset}
`

export const MainContent = styled.main`
  max-width: 1400px;
  ${({ sidebar = false }) =>
    sidebar &&
    `
  filter: blur(5px) brightness(0.7);
  pointer-events: none;
  user-select: none;
  `};

  margin: 0px auto;
  padding: 0px 50px;
  padding-bottom: 62px !important;
  @media (min-width: 768px) {
    padding: 0px 100px;
  }
`

export const HomeSection = styled.section`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;
  margin: 0px auto;
  padding: 150px 0px;
`

export const SectionWrapper = styled(HomeSection)`
  min-height: 100%;
  counter-increment: section 1;
`

const tagStyles = `
  color: ${colors.secondary};
  background-color: transparent;
  font-family: ${fontFamily};
  line-height: 1;
  cursor: pointer;
  font-size: 20px;
  border-width: 3px;
  // border-style: solid;
  border-color: ${colors.secondary};
  border-image: initial;
  border-radius: 2px;
  padding: 12px 17px;
  text-decoration: none;
  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

  &:hover {
    background-color: rgba(100, 255, 218, 0.07);
  }
`

export const SectionInner = styled.div`
  width: 100%;
  @media (min-width: 992px) {
    display: flex;
  }
`

export const ActionLink = styled(Link)`
  ${tagStyles}
`

export const ActionTag = styled.a`
  ${tagStyles}
`

export const Spacer = styled.div`
  width: 100%;
  display: block;
  height: ${props => props.height || 20}px;
`

export const SectionHeader = styled.h1`
  width: 100%;
  display: flex;
  font-size: 30px;
  font-weight: bold;
  position: relative;
  align-items: center;
  margin: 10px 0px 40px;
  font-family: ${fontFamily};

  &:before {
    content: '0' counter(section) '.';
    text-align: right;
    color: ${colors.secondary};
    margin-right: 10px;
  }
`

export const List = styled.li`
  margin-bottom: 10px;
  font-size: 14px;
  list-style: none;
  font-family: ${fontFamily};

  &:before {
    content: '»';
    color: ${colors.secondary};
    font-size: 14px;
    line-height: 12px;
    position: relative;
    right: 5px;
  }
`

export const Paragraph = styled.p`
  margin-bottom: 15px;
  ${({ fontSize = null }) => fontSize && `font-size: ${fontSize}px`};
  ${({ fontFamily = null }) => fontFamily && `font-family: ${fontFamily}`};
`
