import styled from 'styled-components'
import themes from '../../styles/themes'
const { colors } = themes

export const SocialWrapper = styled.div`
  display: flex;
  width: 100%;
  min-height: 70px;
  @media (min-width: 1200px) {
    background-color: ${colors.footer};
    width: auto;
    background: none;
    flex-direction: column;
    position: fixed;
    left: 25px;
    bottom: 60px;
  }

  @media (min-width: 1200px) {
    left: 30px;
  }
`

export const SocialList = styled.ul`
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  @media (min-width: 1200px) {
    flex-direction: column;
  }
`

export const SocialItem = styled.li`
  a {
    color: inherit;
    cursor: pointer;
    padding: 12px 10px;
    position: relative;
    text-decoration: none;
    display: inline-block;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

    &:hover {
      color: ${colors.secondary};
      outline: 0px;
    }
  }
`

export const VLine = styled.div`
  display: none;
  @media (min-width: 1200px) {
    display: block;
    width: 1px;
    right: 2px;
    height: 90px;
    margin-top: 30px;
    background: white;
    position: relative;
  }
`
