import React, { Component } from 'react'
import { FaGithub, FaLinkedin, FaTwitter, FaDev } from 'react-icons/fa'

import * as styles from './styles'

const profiles = [
  { Icon: FaGithub, path: 'https://github.com/harbolaez' },
  { Icon: FaLinkedin, path: 'https://www.linkedin.com/in/harbolaez' },
  { Icon: FaTwitter, path: 'https://twitter.com/arbolaezh' },
  { Icon: FaDev, path: 'https://dev.to/henryarbolaez' },
]

export default class Social extends Component {
  render() {
    return (
      <styles.SocialWrapper>
        <styles.SocialList>
          {profiles.map(({ path, Icon }, idx) => (
            <styles.SocialItem key={Math.random(idx)}>
              <a href={path} target="_blank" rel="noopener noreferrer">
                <Icon size={34} />
              </a>
            </styles.SocialItem>
          ))}
          <styles.VLine />
        </styles.SocialList>
      </styles.SocialWrapper>
    )
  }
}
