import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { MainContent } from '../styles'

import Header from './Header'
import Social from './Social'
import Footer from './Footer'

import './reset.css'
import './layout.css'

class Layout extends Component {
  state = {
    sidebar: false,
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { sidebar } = this.state
    if (
      (!prevState.sidebar && !sidebar && sidebar) ||
      (prevState.sidebar && sidebar)
    ) {
      this.toggleSidenav()
    }
  }

  toggleSidenav = () => {
    this.setState(prevState => ({
      sidebar: !prevState.sidebar,
    }))
  }

  render() {
    const { children } = this.props
    const { sidebar } = this.state

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <Fragment>
            <Header />
            <MainContent sidebar={sidebar}>{children}</MainContent>
            <Social />
            <Footer />
          </Fragment>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
