import React from 'react'
import { FaHeart } from 'react-icons/fa'
import styled from 'styled-components'
import * as styles from '../../styles'
import themes from '../../styles/themes'
const { fontFamily, colors } = themes

const Footer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${colors.footer};
  color: #eff6ee;
  text-align: center;
  height: auto;
  padding: 15px;
  min-height: 60px;

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
`

export default () => (
  <Footer>
    <styles.Paragraph style={{ margin: 0 }} fontFamily={fontFamily}>
      Built by Henry{' '}
      <FaHeart
        style={{ marginLeft: 10, position: 'relative', top: 3 }}
        color="red"
      />
    </styles.Paragraph>
  </Footer>
)
